



















































import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import validation from '../../common/element-val'
import auth, { LogModel } from '../../services/authService'
import store from '../../store'
import { User } from '@/services/data'
import settingService from '@/services/settingService'

@Component
export default class Login extends Vue {
  $refs!: {
    loginForm: ElForm;
  }

  isLoading = false
  login: LogModel = {
    Email: 'debug@debug.com',
    Password: '',
    Language: 'en',
    RememberMe: false
  }

  dialogErrorVisible = false
  error: string = null
  isValid = false
  rules = {
    Email: [
      { trigger: 'blur', validator: validation.required() },
      { trigger: 'blur', validator: validation.email() }],
    Password: validation.requiredRule()
  }

  async mounted () {
    if (process.env.NODE_ENV === 'dev') {
      this.login.Email = 'debug@debug.com'
    }
    document.onkeydown = e => {
      const _key = e.keyCode
      if (_key === 13) {
        this.submit()
      }
    }
  }

  cancel () {
    this.error = null
    this.dialogErrorVisible = false
  }

  changeLang () {
    this.$i18n.locale = this.login.Language
  }

  submit () {
    this.$refs.loginForm.validate(async (valid: boolean) => {
      this.isValid = valid
      if (this.isValid) {
        this.isLoading = true
        try {
          const res = await auth.loginAsync(this.login)
          this.isLoading = false
          if (res.Error) {
            this.error = this.$t('msg.' + res.Error) as string
            this.dialogErrorVisible = true
            return
          }
          const result = res.Result
          const jwt = result.jwt
          if (jwt) {
            const userString = decodeURIComponent(escape(window.atob(jwt.split('.')[1])))
            const user = JSON.parse(userString)
            const userData: User = {
              email: user.LoginId,
              name: user.Name,
              uid: user.UId,
              accNo: user.AccNo,
              jwt: jwt
            }
            store.commit('setUser', userData)
            localStorage.setItem('chromeToken', result.chromeToken)
            if (this.login.RememberMe) {
              localStorage.setItem('jwt', result.jwt)
            }
            const sds = await settingService.readUserSettingsByNamesAsync(['StockDataSource'])
            if (sds.Result && sds.Result.length > 0) {
              if (sds.Result[0].Value) {
                localStorage.setItem('StockDataSource', sds.Result[0].Value)
              }
            }
            await this.$router.push('/home/portfolio')
          }
        } catch (e) {
          this.dialogErrorVisible = true
          this.isLoading = false
          this.error = e
        }
      }
    })
  }
}
